var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "radio-content" }, [
      _vm._m(2),
      _c("label", { staticClass: "mr-2" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.existeBeneficiario,
              expression: "existeBeneficiario",
            },
          ],
          attrs: { type: "radio", name: "radio-group" },
          domProps: {
            value: true,
            checked: _vm._q(_vm.existeBeneficiario, true),
          },
          on: {
            change: function ($event) {
              _vm.existeBeneficiario = true
            },
          },
        }),
        _vm._v("        \n      Sim\n    "),
      ]),
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.existeBeneficiario,
              expression: "existeBeneficiario",
            },
          ],
          attrs: { type: "radio", name: "radio-group", checked: "" },
          domProps: {
            value: false,
            checked: _vm._q(_vm.existeBeneficiario, false),
          },
          on: {
            change: function ($event) {
              _vm.existeBeneficiario = false
            },
          },
        }),
        _vm._v("\n      Não\n    "),
      ]),
    ]),
    _vm.existeBeneficiario
      ? _c(
          "div",
          [
            _vm._l(_vm.beneficiarios, function (beneficiario, i) {
              return _c("div", { key: i, staticClass: "content mt-2" }, [
                _c("div", { staticClass: "card-component" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("p", [_vm._v("Beneficiário " + _vm._s(i + 1))]),
                  ]),
                  _c("div", { staticClass: "flex gap-2" }, [
                    _c("div", { staticClass: "input-content" }, [
                      _vm._m(3, true),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: beneficiario.cnpj,
                              expression: "beneficiario.cnpj",
                            },
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: { value: beneficiario.cnpj },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                beneficiario,
                                "cnpj",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "input-content" }, [
                      _vm._m(4, true),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: beneficiario.name,
                              expression: "beneficiario.name",
                            },
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: { value: beneficiario.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                beneficiario,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "trash-button",
                        on: {
                          click: function ($event) {
                            return _vm.beneficiarios.splice(i, 1)
                          },
                        },
                      },
                      [_c("i", { staticClass: "onpoint-trash" })]
                    ),
                  ],
                  1
                ),
              ])
            }),
            _c("div", [
              _c(
                "div",
                { staticClass: "card-to-button-center mt-2" },
                [
                  _c("Button", {
                    staticClass:
                      "white-button-center p-button-rounded p-button-sm",
                    attrs: { label: "Adicionar mais um beneficiário" },
                    on: {
                      click: function ($event) {
                        return _vm.beneficiarios.push({})
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("Beneficiário")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle" }, [
      _c("p", [_vm._v("Informe caso seja necessário")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mr-5 mb-10" }, [
      _vm._v("\n      Existe cláusula beneficiária "),
      _c("i", { staticClass: "required" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-label" }, [
      _c("p", [
        _vm._v("Cnpj do beneficiário "),
        _c("i", { staticClass: "required" }, [_vm._v("*")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-label" }, [
      _c("p", [
        _vm._v("Razão social do beneficiário "),
        _c("i", { staticClass: "required" }, [_vm._v("*")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }