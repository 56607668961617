var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "card-component" }, [
      _c("div", { staticClass: "title" }, [
        _c("p", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "text" }, [_c("p", [_vm._v(_vm._s(_vm.text))])]),
    ]),
    _c(
      "div",
      [
        _c(
          "Button",
          {
            staticClass: "trash-button",
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          },
          [_c("i", { staticClass: "onpoint-trash" })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }