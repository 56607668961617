<template>
  <div>
    <div>
      <p class="title">Beneficiário</p>
    </div>
    <div class="subtitle">
      <p>Informe caso seja necessário</p>
    </div>
    <div class="radio-content">
      <p class="mr-5 mb-10">
        Existe cláusula beneficiária <i class="required">*</i>
      </p>
      <label class="mr-2">
        <input
          type="radio"
          name="radio-group"
          v-model="existeBeneficiario"
          :value="true"
        />        
        Sim
      </label>
      <label>
        <input
          type="radio"
          name="radio-group"
          v-model="existeBeneficiario"
          checked
          :value="false"
        />
        Não
      </label>     
    </div>
    <div v-if="existeBeneficiario">      
      <div class="content mt-2" v-for="beneficiario,i in beneficiarios" :key="i">
        <div class="card-component">
          <div class="title">
            <p>Beneficiário {{i+1}}</p>
          </div>
          <div class="flex gap-2">
            <div class="input-content">
              <div class="input-label">
                <p>Cnpj do beneficiário <i class="required">*</i></p>
              </div>
              <div>
                <input type="text" class="input" v-model="beneficiario.cnpj" />
              </div>
            </div>
            <div class="input-content">
              <div class="input-label">
                <p>Razão social do beneficiário <i class="required">*</i></p>
              </div>
              <div>
                <input type="text" class="input" v-model="beneficiario.name" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button class="trash-button" @click="beneficiarios.splice(i,1)"
            ><i class="onpoint-trash"></i
          ></Button>
        </div>
      </div>
      <div>
        <div class="card-to-button-center mt-2">
          <Button
            label="Adicionar mais um beneficiário"
            class="white-button-center p-button-rounded p-button-sm"
            @click="beneficiarios.push({})"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
export default {
  components: {
    Button,
  },
  data() {
    return {
      existeBeneficiario: false,
      beneficiarios:[],
    };
  },
  watch:{
    existeBeneficiario(value){
      if(!value){
        this.beneficiarios=[]
      }
      if(value){
        this.beneficiarios.push({})
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.radio-content {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}
.required {
  color: rgba(180, 23, 58, 1);
}

.card-component {
  background-color: rgba(249, 250, 251, 1);
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  width: 100%;
  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding-inline: 24px;
    padding-block: 12px;
    margin: 0px;
  }
}
.trash-button {
  height: 100%;
  margin-left: 30px;
  width: 40px;
  background: rgba(251, 178, 191, 1);
  border-radius: 8px;
  border: none;
  i:before {
    font-size: 24px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.trash-button:hover {
  background: rgba(251, 178, 191, 0.8);
}
.content {
  display: flex;
  flex-direction: row;
}
.card-component {
  background-color: rgba(249, 250, 251, 1);
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  width: 100%;
}
.white-button-center {
  color: black;
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin: 10px;
}

.card-to-button-center {
  background-color: rgba(249, 250, 251, 1);
  box-shadow: none;
  border: none;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.input-label {
  font-weight: 500;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 65, 81, 1);
}
.input {  
  height: 42px;
  border: solid 1px rgba(209, 213, 219, 1);
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  margin-bottom: 20px;
}
.input-content{
  width: 100%;
  padding-inline: 25px ;
}
</style>