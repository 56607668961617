<template>
  <div class="content">
    <div class="card-component">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div class="text">
        <p>{{ text }}</p>
      </div>
    </div>
    <div>
      <Button class="trash-button" @click="$emit('click')"><i class="onpoint-trash"></i></Button>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
export default {
  props: {   
    title: String,
    text: String,
  },
  components: {
    Button,
  },
};
</script>
<style lang='scss' scoped>
.content {
  display: flex;
  flex-direction: row;
}
.card-component {
  background-color: rgba(249, 250, 251, 1);
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  width: 100%;
}
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-inline: 24px;
  padding-block: 12px;
  margin: 0px;
}
.text {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-inline: 24px;
  padding-bottom: 10px;
}

.trash-button {
  height: 71px;
  margin-left: 30px;
  width: 40px;
  background: rgba(251, 178, 191, 1);
  border-radius: 8px;
  border: none;
  i:before {
    font-size: 24px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.trash-button:hover {
  background: rgba(251, 178, 191, 0.8);
}
</style>