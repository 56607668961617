<template>
  <div>
    <Card class="card">
      <template #content>
        <div class="content">
          <Button
            class="p-button-rounded p-button-sm white-button-center"
            label="Incluir nome fantasia"
            @click="dialogVisible = !dialogVisible"
          />         
        </div>
      </template>
    </Card>
    <Dialog :visible.sync="dialogVisible" :closable="false" class="dialog">
      <template #header>
        <div class="header">
          <div class="title-content">
            <p class="title">Nome fantasia</p>
            <Divider class="divider" />
          </div>
          <div class="subtitle-content">
            <p class="mt-8 subtitle">
              Informe o nome fantasia que deseja incluir
            </p>
          </div>
        </div>
      </template>
      <div class="body-dialog-fantasy-name">
        <div>
          <div class="input-label">
            <p>Nome fantasia <i class="required">*</i></p>
          </div>
          <div>
            <input  v-model='nomeFantasia' class="input" @input="validatedFantasyName(nomeFantasia)" />
            <div class="required error-input">
              {{this.erro?this.erro:null}}
            </div>
            
          </div>
        </div>
      </div>
      <template #footer>
        <div class="mt-10">
          <Button label="Cancelar" class="cancel-button" @click="dialogVisible = false"></Button>
          <Button label="Incluir nome"  @click="saveFantasyName()" :disabled="disabledButton"></Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Divider from "primevue/divider";

import axiosInstance from "@/axios";

export default {
  components: {
    Button,
    Dialog,
    Card,
    Divider,
  },
  props:{
    segurado:Object
  },
  data() {
    return {
      dialogVisible: false,
      nomeFantasia: "",
      erro:null
    };
  },
  computed:{
    disabledButton(){
      return !(this.nomeFantasia.length>0 && this.erro==null)
    }
  },
  methods:{    
    saveFantasyName() {
      return this.$onpoint.loading(() => {
        let data = {
          PersonId: this.segurado.Id,
          Name: this.nomeFantasia
        };
        return axiosInstance({
          method: "post",
          url: "/api/person/SaveFantasyName",
          data: data
        })
          .then(response => {            
            this.$onpoint.successModal( this.segurado.Entity === 'J' ?  "Nome Fantasia cadastrado com sucesso.": "Nome Social cadastrado com sucesso!");            
            this.dialogVisible =false
            this.$emit('newSocialName')
          })
          .catch(response => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    },
    validatedFantasyName(input){          
      const regex = /^[0-9a-zÀ-ÿA-Z\(\)\-\– \.]*$/

      if(regex.test(input)){
       this.erro = null
       this.novoNomeFantasia = input.replace('–', '-') 
        return true
      }
      else{
        this.erro = 'Não são aceitos caractéres especiais'
        return false
      }   
    },
    removeFantasyName(item, index) {
      this.$onpoint.loadingModal("Processando sua solicitação dados", () => {
        return axiosInstance
          .delete(`/api/person/RemoveFantasyName?fantasyNameId=${item.Id}`)
          .then(() => {
            this.searchFantasyName();
            if (item.Id == this.fantasyNameSelecionado) {
              this.fantasyNameSelecionado = [];
              this.updateFantasyNameProposal(
                this.$store.state["proposta-module"].fantasyName
              );
            }
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.white-button-center {
  color: black;
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.card {
  width: 100%;
  background-color: rgba(249, 250, 251, 1);
  box-shadow: none;
  border: none;
  border-radius: 12px;
  :deep(.p-card-content) {
    padding: 0px;
  }
  .content {
    display: flex;
    justify-content: center;
  }
}
:deep(.dialog) {
  min-width: 697px;
  border-radius: 12px;
  .p-dialog-header {
    display: flex;
    justify-content: center;
  }
  .header {
    width: 100%;
  }
  .title {
    margin-top: 10px;
    font-weight: 700;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .subtitle-content {
    display: flex;
    justify-content: center;
    .subtitle {
      font-weight: 400;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: rgba(107, 114, 128, 1);
    }
  }
  .p-dialog.p-dialog-footer button {
    margin: 0px;
  }  
}

.body-dialog-fantasy-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .input-label{
      font-weight: 500;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: rgba(55, 65, 81, 1);
    }
    .input{
      min-width:422px ;
      height: 42px;
      border: solid 1px rgba(209, 213, 219, 1);
      border-radius: 8px;      
      padding: 5px;
    }
  }
.required {
  color: rgba(180, 23, 58, 1);
}
.cancel-button{
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right:5px ;
  width: 120px;
}
.error-input{
  padding: 0px;
  margin-inline: 5px;
  font-size: 12px;
}
</style>