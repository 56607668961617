<template>
  <section>
    <Card class="card">
      <template #title>
        <div class="title">
          <p>Informações do segurado</p>
        </div>
      </template>
      <template #subtitle>
        <div class="subtitle">
          <p>Informe dos dados do segurado</p>
        </div>
      </template>
      <template #content>
        <div>
          <div>
            <InputSearchSelect
              placeholder="Selecionar"
              label="Digite o CNPJ ou Razão Social para buscar um segurado."
              :required="true"
              icon="onpoint-caret-down"
              v-model="cnpjRazaoSocial"
              :infoData="dadosBuscaSegurado"
              :isLoading="isLoadingSearchSegurado"
              width="70%"
            >
              <template v-slot:infoData>
                <div v-for="(item, i) in dadosBuscaSegurado" :key="i">
                  <div
                    @click="
                      validarSegurado(item.DocumentNumber, formData.cnpjTomador)
                    "
                  >
                    <Card class="item-searched" v-if="i < 3">
                      <template #title>
                        <div class="title">
                          {{ item.Name }}
                        </div>
                      </template>
                      <template #content>
                        <div class="informations">
                          {{ item.DocumentNumber }}
                        </div>
                      </template>
                    </Card>
                  </div>
                </div>
              </template>
            </InputSearchSelect>
          </div>
          <div v-if="selectedSegurado">
            <InformacoesSegurado class="mt-3"
              :segurado="selectedSegurado"
            ></InformacoesSegurado>
          </div>
          <div v-if="selectedSegurado && !selectedSegurado.FantasyName" >          
            <IncluirNomeFantasia :segurado='selectedSegurado' @newSocialName="searchFantasyName" class="mt-3"/>
          </div>
          <div v-if="selectedSegurado && !selectedSegurado.Locations[1]">
            <IncluirEnderecoAdicional 
              :segurado="selectedSegurado" 
              class="mt-3"
              @novoEndereco="renderNewEndereco"
            />
          </div>
          <div v-if="seguradoFantasyNames">                  
            <CardInfoWithDeleteButton 
            v-for="item,i in seguradoFantasyNames"
            :key="i"
            title="Nome fantasia" 
            class="mt-3"
            :text="item.Name"
            @click="removeFantasyName(item) "
            />
          </div>
          <div v-if="selectedSegurado && selectedSegurado.Locations[1]" >     
            <div :key="i" v-for="location,i in selectedSegurado.Locations">
              <CardInfoWithDeleteButton 
                v-if="!location.IsMain"
                title="Endereço adicional" 
                class="mt-3"
                :text="location.Address"
                @click="removeEndereco(location.LocationId)"
              />
            </div>      
           
          </div>
          <Divider class="divider"></Divider>
          <div>
            <Beneficiario/>
          </div>          
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <Button
            label="Voltar"
            class="p-button-rounded cancel-button"
            @click="prevPage()"
          />
          <Button
            label="Próximo"
            class="p-button-rounded"
            @click="nextPage()"
          />
        </div>
      </template>
    </Card>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import InputSearchSelect from "../../../components/onpoint-input-text-search-select";
import InformacoesSegurado from "/src/views/propostas-new/components/InformaçõesSegurado.vue";
import Divider from "primevue/divider";
import IncluirNomeFantasia from './IncluirNomeFantasia.vue';
import IncluirEnderecoAdicional from './IncluirEnderecoAdicional.vue'
import CardInfoWithDeleteButton from './CardInfoWithDeleteButton.vue';
import Beneficiario from './Beneficiario.vue';

import * as Utils from "@/utils/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";



export default {
  props: {
    formData: Object,
  },
  components: {
    Card,
    Button,
    InputSearchSelect,
    InformacoesSegurado,
    Divider,
    IncluirNomeFantasia,
    IncluirEnderecoAdicional,
    CardInfoWithDeleteButton,
    Beneficiario
  },
  data() {
    return {
      _refreshDelayPromise: undefined,
      dadosBuscaSegurado: [],
      isLoadingSearchSegurado: false,
      cnpjRazaoSocial: null,
      cnpjTomador: null,
      selectedSegurado: null,
      seguradoFantasyNames:null
    };
  },
  watch: {
    cnpjRazaoSocial(value) {
      if (value.length >= 3) {
        return this.buscarSegurado(value);
      }
      return this.clearAll();
    },
  },
  methods: {
    nextPage() {
      this.$emit("nextPage", { formData: {}, pageIndex: 1 });
    },
    prevPage() {
      this.$emit("prevPage", { pageIndex: 1 });
    },
    ...mapActions("proposta-module", [
      "validaSeguradoPorCnpj",
      "buscaSeguradoPorTexto",
    ]),    
    ...mapMutations("proposta-module", ["updateFantasyName","updatePropostaEnderecosSegurado"]),
    clearAll() {
      this.dadosBuscaSegurado = [];
    },
    buscarSegurado(query) {
      if (!query || query.length < 3) {
        return this.clearAll();
      }
      if (this._refreshDelayPromise) {
        clearTimeout(this._refreshDelayPromise);
      }
      this._refreshDelayPromise = setTimeout(
        async function () {
          this.isLoadingSearchSegurado = true;
          this.dadosBuscaSegurado = [];
          await this.buscaSeguradoPorTexto(Utils.removeSpecialChars(query))
            .then((response) => {
              this.isLoadingSearchSegurado = false;
              if (!response) response = [];
              this.dadosBuscaSegurado = response;
            })
            .catch(() => {
              this.$onpoint.errorModal(
                "Ocorreu um erro ao tentar buscar os dados do segurado."
              );
            })
            .finally(() => {
              this.isLoadingSearchSegurado = false;
            });
        }.bind(this),
        500
      );
    },
    async validarSegurado(cnpjSegurado, cnpjTomador) {
      if (!cnpjSegurado) return;

      this.$appInsights.trackEvent({
        name: "inserir-segurado-proposta",
        properties: {
          place: "proposta-passo-2",
          action: "button-click",
          slug: "inserir-segurado-proposta-passo-2",
        },
      });
      await this.$onpoint.loading(async () => {
        const data = {
          InsuredDocument: Utils.removeSpecialChars(cnpjSegurado),
          PolicyHolderCnpj: cnpjTomador,
        };
        return await this.validaSeguradoPorCnpj(data)
          .then((response) => {
            // Necessário para salvar backup da Razão social, caso usuário escolha em apagar ou desclickar no item
            this.updateFantasyName(response.Name);
            this.selectedSegurado = response;
            this.searchFantasyName()
            setTimeout(() => {
              this.cnpjRazaoSocial = "";
              this.dadosBuscaTomador = [];              
            }, 200);
          })
          .catch((errors) => {
            this.novoEndereco = {};
            this.$onpoint.errorModal(errors.response.data.Errors);
          })
          .finally(() => {
            window.scrollTo(0, 0);
          });
      });
    },
    removeEndereco(id) {
      this.$onpoint.loadingModal("Processando sua solicitação dados", () => {
        return axiosInstance
          .delete(`/api/Insured/RemoveInsuredLocation?locationId=${id}`)
          .then(() => {
            const copiaEnderecos = this.selectedSegurado.Locations.filter(x => x.Id !== id)
            this.selectedSegurado.Locations = copiaEnderecos

            this.updatePropostaEnderecosSegurado(copiaEnderecos);// não esta mudadndo o vuex
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    renderNewEndereco(event){
      this.selectedSegurado.Locations= event      
    },
    removeFantasyName(item) {
      this.$onpoint.loadingModal("Processando sua solicitação dados", () => {
        return axiosInstance
          .delete(`/api/person/RemoveFantasyName?fantasyNameId=${item.Id}`)
          .then(() => {
            this.searchFantasyName();
            if (item.Id == this.fantasyNameSelecionado) {
              this.fantasyNameSelecionado = [];
              this.updateFantasyNameProposal(
                this.$store.state["proposta-module"].fantasyName
              );
            }
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    searchFantasyName() {      
      this.$onpoint.loading(() => {          
        return axiosInstance
          .get(`/api/person/GetFantasyNames?PersonId=${this.selectedSegurado.Id}`)
          .then(response => {     
            this.seguradoFantasyNames = response.data.Response
          })
          .catch(error => {
            this.cep = "";
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },  
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
}
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.divider {
  padding: 10px;
}
.item-searched {
  box-shadow: none;
  margin: 2px;
  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .informations {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }
  :deep(.p-card-content) {
    padding: 0px;
  }
}
.item-searched:hover {
  background-color: rgba(243, 244, 246, 1);
}
.item-searched:active {
  background-color: rgba(var(--vs-primary, 1));
  color: white;
}
.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
  width: 87px;
}
.radio-content{
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}
.required {
  color: rgba(180, 23, 58, 1);
}
</style>