<template>
  <div>
    <Card class="card">
      <template #content>
        <div class="content">
          <Button
            class="p-button-rounded p-button-sm white-button-center"
            label="Incluir endereço adicional"
            @click="dialogVisible = !dialogVisible"
          />
        </div>
      </template>
    </Card>
    <Dialog :visible.sync="dialogVisible" :closable="false" class="dialog">
      <template #header>
        <div class="header">
          <div class="title-content">
            <p class="title">Novo endereço</p>            
            <Divider class="divider" />
          </div>
          <div class="subtitle-content">
            <p class="mt-8 subtitle">informe o endereço que deseja incluir</p>
          </div>          
        </div>
      </template>
      <div class="body-dialog-addres">
        <div>
          <div class="input-label">
            <p>CEP<i class="required">*</i></p>          
          </div>
          <div>
            <input  v-model="endereco.ZipCode" class="input" />
          </div>
        </div>
        <div>
          <div class="input-label">
            <p>Logradouro<i class="required">*</i></p>
          </div>
          <div>
            <input
              class="input"
              v-model="endereco.AddressName"             
            />
          </div>
        </div>
        <div class="grid grid-cols-3 line-with-two-items">
          <div class="">
            <div class="input-label">
              <p>Número<i class="required">*</i></p>
            </div>
            <div>
              <input              
                class="input-numero"
                v-model="endereco.Number"
              />
            </div>
          </div>
          <div class="col-span-2">
            <div class="input-label">
              <p>Complemento<i class="required">*</i></p>
            </div>
            <div>
              <input
                v-model="endereco.Complement"
                class="input-complemento"                
              />
            </div>
          </div>
        </div>
        <div>
          <div class="input-label">
            <p>Bairro<i class="required">*</i></p>
          </div>
          <div>
            <input
              class="input"
              v-model="endereco.Neighborhood"             
            />
          </div>
        </div>
        <div class="grid grid-cols-3 line-with-two-items">
          <div class="col-span-2">
            <div class="input-label">
              <p>Cidade<i class="required">*</i></p>
            </div>
            <div>
              <input
                class="input-cidade"
                v-model="endereco.CityName"  
              />
            </div>
          </div>
          <div class="estado">
            <div>
              <div class="input-label">
                <p>Estado<i class="required">*</i></p>
              </div>
              <div>
                <input
                  v-model="endereco.StateProvinceName "
                  class="input-estado"                  
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          class="cancel-button"
          @click="(dialogVisible = false), (endereco = {})"
        ></Button>
        <Button
          label="Incluir Endereço"
          @click="salvarendereco()"
          :disabled="!adicionarEndereco"
        ></Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Divider from "primevue/divider";

import { mapGetters, mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";

export default {
  components: {
    Button,
    Dialog,
    Card,
    Divider,
  },
  props:{
    segurado:Object
  },
  data() {
    return {
      dialogVisible: false,
      endereco: {},
      teste:null
    };
  },
  computed:{
    adicionarEndereco() {      
      return (
        this.endereco.AddressName &&
        this.endereco.Number &&
        this.endereco.Neighborhood &&
        this.endereco.ZipCode &&
        this.endereco.CityName &&
        this.endereco.StateProvinceName
      );
    }
  }, 
  methods:{
    ...mapMutations("proposta-module", [      
      "updatePropostaEnderecosSegurado",
    ]),
     async salvarendereco() {
      const enderecoNovo = {
        ...this.endereco,
        PersonId: this.segurado.Id
      };
      return await this.$onpoint.loading(async() => {        
        return await axiosInstance({
          method: "post",
          url: "/api/Insured/SaveInsuredLocation",
          data: enderecoNovo
        })
          .then(response => {
            this.updatePropostaEnderecosSegurado(response.data.Response);            
            this.dialogVisible = false
            console.log(response.data.Response);
            
            this.$emit('novoEndereco', response.data.Response)
            this.endereco={},
            this.$onpoint.successModal("Endereço cadastrado com sucesso.");            
          })
          .catch(response => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.white-button-center {
  color: black;
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.card {
  width: 100%;
  background-color: rgba(249, 250, 251, 1);
  box-shadow: none;
  border: none;
  border-radius: 12px;
  :deep(.p-card-content) {
    padding: 0px;
  }
  .content {
    display: flex;
    justify-content: center;
  }
}
:deep(.dialog) {
  min-width: 697px;
  border-radius: 12px;
  .p-dialog-header {
    display: flex;
    justify-content: center;
  }
  .header {
    width: 100%;
  }
  .title {
    margin-top: 10px;
    font-weight: 700;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .subtitle-content {
    display: flex;
    justify-content: center;
    .subtitle {
      font-weight: 400;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: rgba(107, 114, 128, 1);
    }
  }
  .p-dialog.p-dialog-footer button {
    margin: 0px;
  }
}

.body-dialog-addres {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .input-label {
    font-weight: 500;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: rgba(55, 65, 81, 1);
  }
  .input {
    min-width: 422px;
    height: 42px;
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    margin-bottom: 30px;
    padding-inline: 5px;
  }
  .input-numero {
    width: 103px;
    height: 42px;
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    margin-bottom: 30px;
    padding-inline: 5px;
  }
  .input-complemento {
    width: 100%;
    height: 42px;
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    margin-bottom: 30px;
    padding-inline: 5px;
  }
  .input-estado {
    width: 103px;
    height: 42px;
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    margin-bottom: 30px;
    display: flex;
    padding-inline: 5px;
  }
  .input-cidade {
    width: 100%;
    height: 42px;
    border: solid 1px rgba(209, 213, 219, 1);
    border-radius: 8px;
    margin-bottom: 30px;
    padding-inline: 5px;
  }
}
.required {
  color: rgba(180, 23, 58, 1);
}
.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
  width: 120px;
}
.line-with-two-items {
  width: 422px;
}
.estado {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>