var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("Button", {
                      staticClass:
                        "p-button-rounded p-button-sm white-button-center",
                      attrs: { label: "Incluir nome fantasia" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = !_vm.dialogVisible
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "Dialog",
        {
          staticClass: "dialog",
          attrs: { visible: _vm.dialogVisible, closable: false },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "header" }, [
                    _c(
                      "div",
                      { staticClass: "title-content" },
                      [
                        _c("p", { staticClass: "title" }, [
                          _vm._v("Nome fantasia"),
                        ]),
                        _c("Divider", { staticClass: "divider" }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "subtitle-content" }, [
                      _c("p", { staticClass: "mt-8 subtitle" }, [
                        _vm._v(
                          "\n            Informe o nome fantasia que deseja incluir\n          "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      _c("Button", {
                        staticClass: "cancel-button",
                        attrs: { label: "Cancelar" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      }),
                      _c("Button", {
                        attrs: {
                          label: "Incluir nome",
                          disabled: _vm.disabledButton,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.saveFantasyName()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "body-dialog-fantasy-name" }, [
            _c("div", [
              _c("div", { staticClass: "input-label" }, [
                _c("p", [
                  _vm._v("Nome fantasia "),
                  _c("i", { staticClass: "required" }, [_vm._v("*")]),
                ]),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nomeFantasia,
                      expression: "nomeFantasia",
                    },
                  ],
                  staticClass: "input",
                  domProps: { value: _vm.nomeFantasia },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.nomeFantasia = $event.target.value
                      },
                      function ($event) {
                        return _vm.validatedFantasyName(_vm.nomeFantasia)
                      },
                    ],
                  },
                }),
                _c("div", { staticClass: "required error-input" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(this.erro ? this.erro : null) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }